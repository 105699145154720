import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import img1 from './img/smiling-face-with-sunglasses_1f60e.png';
import img2 from './img/pancakes_1f95e.png';
import img3 from './img/astonished-face_1f632.png';
import img4 from './img/party-popper_1f389.png';
import img5 from './img/tram-car_1f68b.png';
import img6 from './img/hamburger_1f354.png';

const Hello = () => {
  
  const urls = [img1, img2, img3, img4, img5, img6];

  const [url, setUrl] = useState(urls[1]);

  const setNewUrl = () => {
    const ind = Math.floor(Math.random() * Math.floor(urls.length));
    setUrl(urls[ind]);
  };

  return (
    <div style={{ width: '100%', paddingTop: '10rem', textAlign: 'center' }}>
      <img src={url} onClick={setNewUrl} style={{ cursor: 'pointer' }} />
    </div>
  );
};

const mountNode = document.getElementById("app");
ReactDOM.render(<Hello />, mountNode);